import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SlideNavButton from '~components/buttons/SlideNavButton/SlideNavButton';

const useStyles = makeStyles({
  navButtonContainer: {
    display: 'flex',
    gap: 14,
    marginRight: '15px'
  }
});

const NavigationButtons = ({
  sliderRef,
  numberOfCards,
  selectedSlideIndex,
  cardsToShow,
  variant
}) => {
  const classes = useStyles();

  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  useEffect(() => {
    const isLast = selectedSlideIndex + cardsToShow === numberOfCards;
    const isFirst = selectedSlideIndex === 0;
    setDisableNext(isLast);
    setDisablePrev(isFirst);
  }, [selectedSlideIndex, numberOfCards, cardsToShow]);

  const navigateNext = () => {
    sliderRef.current.slickNext();
  };

  const navigatePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className={classes.navButtonContainer}>
      <SlideNavButton
        onClick={navigatePrev}
        direction="prev"
        disabled={disablePrev}
        variant={variant}
      />
      <SlideNavButton
        onClick={navigateNext}
        direction="next"
        disabled={disableNext}
        variant={variant}
      />
    </div>
  );
};

NavigationButtons.propTypes = {
  sliderRef: PropTypes.shape({
    current: PropTypes.shape({
      slickNext: PropTypes.func,
      slickPrev: PropTypes.func
    })
  }).isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
  cardsToShow: PropTypes.number.isRequired,
  numberOfCards: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['light', 'dark'])
};

NavigationButtons.defaultProps = {
  variant: 'light'
};

export default NavigationButtons;
