import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BaseSlider from './BaseSlider';
import VideoCard from './VideoCard';
import NavigationButtons from './NavigationButtons';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginTop: '90px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '58px'
    }
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '140%',
    marginLeft: '15px',
    marginTop: 0,
    marginBottom: '18px'
  },
  navigationArea: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px'
  }
}));

const RelatedContentCarousel = ({ playlists, title, variant }) => {
  const theme = useTheme();
  const sliderRef = useRef();
  const classes = useStyles();
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);

  const isExtraSmallerScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [cardsToShow, setCardsToShow] = useState(3);

  useEffect(() => {
    setSelectedSlideIndex(0);
    if (sliderRef?.current) {
      sliderRef.current.slickGoTo(0, true);
    }
  }, [sliderRef]);

  useEffect(() => {
    if (isExtraSmallerScreen) {
      setCardsToShow(1);
    } else if (isSmallerScreen) {
      setCardsToShow(2);
    } else {
      setCardsToShow(3);
    }
  }, [isExtraSmallerScreen, isSmallerScreen]);

  const beforeSlideChange = nextSlide => {
    setSelectedSlideIndex(nextSlide);
  };

  return (
    <div className={classes.container}>
      <div className={classes.navigationArea}>
        <h4 className={classes.title}>{title}</h4>

        {!isExtraSmallerScreen && playlists?.length > cardsToShow && (
          <NavigationButtons
            sliderRef={sliderRef}
            cardsToShow={cardsToShow}
            selectedSlideIndex={selectedSlideIndex}
            numberOfCards={playlists?.length || 0}
            variant={variant}
          />
        )}
      </div>

      <Grid container alignItems="stretch" gap={2}>
        <BaseSlider ref={sliderRef} beforeSlideChange={beforeSlideChange}>
          {playlists.map(card => (
            <VideoCard key={card?.id} {...card} labelOnImage={isMediumScreen} variant={variant} />
          ))}
        </BaseSlider>
      </Grid>
    </div>
  );
};

RelatedContentCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.string,
      duration: PropTypes.string
    })
  ).isRequired,
  variant: PropTypes.oneOf(['light', 'dark'])
};

RelatedContentCarousel.defaultProps = {
  variant: 'dark'
};

export default RelatedContentCarousel;
