import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  carousel: {
    width: '100%',
    '& .slick-list': {
      padding: '8px 0',
      position: 'unset'
    },
    '& .slick-track': {
      display: 'flex !important',
      gap: 15,
      position: 'unset',
      marginLeft: 0,
      marginRight: 0
    },
    '& .slick-slider': {
      position: 'unset'
    },
    '& .slick-slide': {
      height: 'inherit !important',
      '& > :first-child': {
        height: '100%'
      }
    }
  }
});

const BaseSlider = React.forwardRef(({ children, beforeSlideChange }, ref) => {
  const classes = useStyles();

  const carouselSettings = {
    className: classes.carousel,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (current, next) => beforeSlideChange && beforeSlideChange(next, current),
    responsive: [
      {
        breakpoint: 599,
        settings: { slidesToShow: 1.1, slidesToScroll: 1 }
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <Slider ref={ref} {...carouselSettings}>
      {children}
    </Slider>
  );
});

BaseSlider.propTypes = {
  children: PropTypes.node.isRequired,
  beforeSlideChange: PropTypes.func
};

BaseSlider.defaultProps = {
  beforeSlideChange: null
};

export default BaseSlider;
